import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import styles from './SectionSafeList.module.css';

export type SafeSummaryDataProps = {
  token: string;
  capacity: number;
  userPosition: number;
  depositFee: number;
  apy: number;
  icon: string;
  iconBgColor: string;
};

export function SafeSummary({ token, capacity, userPosition, apy, icon, iconBgColor }: SafeSummaryDataProps) {
  return (
    <Link href={`/#/safe/${token}`} underline='none'>
      <Box className={styles.summaryBox}>
        <Box className={styles.summaryBoxHeader}>
          <Box className={styles.iconBox} bgcolor={iconBgColor}>
            <img className={styles.tokenIcon} src={icon} alt="token icon" />
          </Box>
          <Typography className={styles.safeTitle} variant='h4'>
            {token} SAFE
          </Typography>
        </Box>
        <Typography className={styles.description} variant='subtitle1'>
          Deposit {token} and protect your stablecoin risk
        </Typography>
        <Box className={styles.row}>
          <Typography className={styles.key} variant='subtitle1'>Capacity</Typography>
          <Typography className={styles.value} variant='subtitle1'>{parseFloat(capacity.toFixed(2))}&nbsp;{token}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography className={styles.key} variant='subtitle1'>Your Position</Typography>
          <Typography className={styles.value} variant='subtitle1'>{parseFloat(userPosition.toFixed(2))}&nbsp;{token}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography className={styles.key} variant='subtitle1'>APY</Typography>
          <Typography className={styles.value} variant='subtitle1'>{parseFloat(apy.toFixed(2))}%</Typography>
        </Box>
      </Box>
    </Link>
  );
}
