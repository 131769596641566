import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styles from './Portfolio.module.css';
import SafeContext from '../../../context/SafeContext';

type TypeSafeAmount = {
  safe: string;
  amount: number;
};

export function SafeList() {
  const { safes } = useContext(SafeContext);
  const [safeAmounts, setSafeAmounts] = useState<TypeSafeAmount[]>([]);

  useEffect(() => {
    const _safeAmounts = safes.map(safe => {
      return {
        safe: `Safe${safe.tokenName}`,
        amount: safe.totalSupply
      };
    });
    setSafeAmounts(_safeAmounts);
  }, [safes]);

  return (
    <Box className={styles.safeList}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className={styles.th}>Safe</Typography>
            </TableCell>
            <TableCell>
              <Typography className={styles.th}>Amount Protected</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {safeAmounts.map((safeAmount, id) => (
            <TableRow className={styles.tr} key={id}>
              <TableCell>
                <Typography className={styles.td}>{safeAmount.safe}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.td}>{parseFloat(safeAmount.amount.toFixed(2))}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
