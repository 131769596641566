import { getERC20, getDecimals, toNDecimals, bedRockCore } from "./common";

export const deposit = async (tokenAddress, _amount, fromAddress = undefined) => {
  const token = getERC20(tokenAddress);
  const decimals = await token.methods.decimals().call();
  const amount = toNDecimals(_amount, decimals);

  await token.methods.approve(bedRockCore.options.address, amount).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
  await bedRockCore.methods.deposit(tokenAddress, amount).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}

export const withdrawRequest = async (tokenAddress, _amount, delay, fromAddress = undefined) => {
  const decimals = await getDecimals(tokenAddress);
  const amount = toNDecimals(_amount, decimals);
  console.log({ tokenAddress, amount, delay });
  await bedRockCore.methods.requestWithdraw(tokenAddress, amount, delay).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}

export const requestClaim = async (tokenAddress, _amount, fromAddress = undefined) => {
  const decimals = await getDecimals(tokenAddress);
  const amount = toNDecimals(_amount, decimals);
  await bedRockCore.methods.requestClaim(tokenAddress, amount).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}

export const withdrawProceed = async (id, fromAddress = undefined) => {
  await bedRockCore.methods.withdrawProceed(id).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}

export const releaseLockedAsset = async (id, fromAddress = undefined) => {
  await bedRockCore.methods.releaseLockedAsset(id).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}
