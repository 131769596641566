import { Typography } from "@mui/material";
import { TypeSafeInfo } from "../../../web3";
import styles from './TokenProperty.module.css';

export function BUSD({ safeInfo }: { safeInfo: TypeSafeInfo }) {
  return (
    <Typography className={styles.descriptionText} variant='body1'>
      This Safe provides insurance on BUSD.BUSD is a fiat-backed stablecoin issued by Binance and Paxos. Each BUSD token is backed 1:1
      with US dollars held in reserve. BUSD aims to provide a more stable cryptocurrency alternative for traders and investors who are
      looking to avoid the volatility of the digital asset market.

      BUSD is an ERC20 token built on the Ethereum blockchain. However, it also supports BEP-2. BEP-2 is a technical standard that defines
      how new digital assets can be created and transferred on the Binance Chain.

      <br />
      <br />
      This safe covers the risk to reclaim BUSD on Ethereum for less than {safeInfo.safeConfig.hackThreshold} USDC.
      <br />
      <br />
      <strong>Risks</strong>
      <br />
      In the event of a hack or depeg event, the policyholder will receive payment in a collection of stablecoins closer
      to their peg. Other Bedrock vault assets will be liquidated to partially compensate policy holders.
      The vault may be unable to provide the full value of the protection if another protocol
      in the vault experiences a default event before this pool does. While this event is unlikely
      to occur it is a possibility.
    </Typography>
  );
}
