import { Typography } from "@mui/material";
import { TypeSafeInfo } from "../../../web3";
import styles from './TokenProperty.module.css';

export function DAI({ safeInfo }: { safeInfo: TypeSafeInfo }) {
  return (
    <Typography className={styles.descriptionText} variant='body1'>
      DAI is an algorithmic stablecoin issued by MakerDAO, an Ethereum-based protocol, 
      that seeks to maintain an exact ratio of one-to-one with the U.S. dollar. It is primarily used 
      as a means of lending and borrowing crypto assets without the need for an intermediary — creating a 
      permissionless system with transparency and minimal restrictions.
      <br />
      <br />
      This safe covers the risk to reclaim DAI on Ethereum for less than {safeInfo.safeConfig.hackThreshold} USDC.
      <br />
      <br />
      <strong>Risks</strong>
      <br />
      In the event of a depeg event, the policyholder will receive payment in a collection of stablecoins closer
      to their peg. Other Bedrock vault assets will be liquidated to partially compensate policy holders.
      The vault may be unable to provide the full value of the protection if another protocol
      in the vault experiences a default event before this pool does. While this event is unlikely
      to occur it is a possibility.
    </Typography>
  );
}