import BigNumber from "bignumber.js";
import Web3 from 'web3';

export const toDecimals = (value, decimals = 18) => {
  const TENPOW_ = new BigNumber(10).pow(decimals);
  return new BigNumber(value).div(TENPOW_).toFixed();
};

export const toNDecimals = (value, decimals) => {
  const TENPOW = new BigNumber(10).pow(decimals);
  return new BigNumber(value).multipliedBy(TENPOW).toFixed(0);
};

export const TOKEN_LIST = {
  BUSD: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
  DAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7"
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const STANDARD_DECIMALS = 18;
export const DENOMINATOR_DECIMALS = 4;
export const MAX_PERCENT = 100000;

export const NETWORK = process.env.REACT_APP_NETWORK;
export const BEDROCK_ADDRESS = process.env.REACT_APP_BEDROCK_ADDRESS;
export const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY;

const abiBedRockCore = require('../abis/BedRockCore.json');
const abiIPriceProvider = require('../abis/IPriceProvider.json');
const abiERC20 = require('../abis/ERC20.json');

const provider = new Web3.providers.HttpProvider(
  "https://" + NETWORK.toLocaleLowerCase() + ".infura.io/v3/" + INFURA_API_KEY
);
const web3 = new Web3(provider);
export const bedRockCore = new web3.eth.Contract(abiBedRockCore, BEDROCK_ADDRESS);

export const getERC20 = (tokenAddress) => {
  return new web3.eth.Contract(abiERC20, tokenAddress);
}

export const getPriceProvider = (address) => {
  return new web3.eth.Contract(abiIPriceProvider, address);
}

export const getSafeCnt = async () => {
  let safeCnt = 0;
  console.log({bedRockCore});
  if (bedRockCore) {
    const safeCounter = await bedRockCore.methods.safeCounter().call();
    await Promise.all(
      [...Array(parseInt(safeCounter)).keys()].map(async (id) => {
        const tokenAddress = await bedRockCore.methods.safeArray(id + 1).call();
        const isActive = await bedRockCore.methods.isActive(tokenAddress).call();
        if (isActive) safeCnt++;
      })
    );
  }
  return safeCnt;
}

export const getSafes = async () => {
  if (bedRockCore) {
    const safeCounter = await bedRockCore.methods.safeCounter().call();
    let safes = await Promise.all(
      [...Array(parseInt(safeCounter)).keys()].map(async (id) => {
        const tokenAddress = await bedRockCore.methods.safeArray(id + 1).call();
        const isActive = await bedRockCore.methods.isActive(tokenAddress).call();
        const tokenObj = getERC20(tokenAddress);
        const decimals = await tokenObj.methods.decimals().call();
        const [tokenName, totalSupply, _claimLockPercent] = await Promise.all([
          tokenObj.methods.symbol().call(),
          getSafeTotalSupply(tokenAddress, decimals),
          bedRockCore.methods.claimLockPercent(tokenAddress).call()
        ]);
        const claimLockPercent = parseFloat(toDecimals(_claimLockPercent, DENOMINATOR_DECIMALS));
        const safeConfig = await getSafeConfig(tokenAddress);
        const _price = await getPriceProvider(safeConfig.priceProvider).methods.getPrice().call();
        const price = parseFloat(toDecimals(_price, STANDARD_DECIMALS));

        const safe = {
          tokenAddress,
          isActive,
          tokenName,
          decimals,
          price,
          claimLockPercent,
          totalSupply,
          safeConfig
        };
        return safe;
      })
    );
    return safes.filter(x => x !== undefined);
  }
  return [];
}

export const getSafeBalance = async (tokenAddress, userAddress) => {
  const decimals = await getDecimals(tokenAddress);
  const balance = await bedRockCore.methods.safeBalanceOf(tokenAddress, userAddress).call();
  return parseFloat(toDecimals(balance, decimals));
}

export const getDecimals = async (tokenAddress) => {
  const token = getERC20(tokenAddress);
  return await token.methods.decimals().call();
}

export const getBalance = async (tokenAddress, userAddress, decimals = undefined) => {
  const token = getERC20(tokenAddress);
  const balance = await token.methods.balanceOf(userAddress).call();
  if (decimals === undefined) {
    decimals = await token.methods.decimals().call();
  }
  return parseFloat(toDecimals(balance, decimals));
}

export const getTotalSupply = async (tokenAddress, decimals = undefined) => {
  const token = getERC20(tokenAddress);
  const totalSupply = await token.methods.totalSupply().call();
  if (decimals === undefined) {
    decimals = await token.methods.decimals().call();
  }
  return parseFloat(toDecimals(totalSupply, decimals));
}

export const getSafeTotalSupply = async (tokenAddress, decimals = undefined) => {
  const token = getERC20(tokenAddress);
  if (decimals === undefined) {
    decimals = await token.methods.decimals().call();
  }
  const totalSupply = await bedRockCore.methods.safeTotalSupply(tokenAddress).call();
  return parseFloat(toDecimals(totalSupply, decimals));
}

export const isRegisteredSafe = async (safeAddress) => {
  const registered = await bedRockCore.methods.safeMapping(safeAddress).call();
  return registered;
}

export const getSafeConfig = async (tokenAddress) => {
  const safeConfig = await bedRockCore.methods.getSafeConfig(tokenAddress).call();
  return {
    expectPrice: safeConfig.expectPrice,
    priceProvider: safeConfig.priceProvider,
    feeFreePeriod: safeConfig.feeFreePeriod,
    hackThreshold: parseFloat(toDecimals(safeConfig.hackThreshold, DENOMINATOR_DECIMALS)),
    claimLockPeriod: safeConfig.claimLockPeriod,
    depositFeePercent: parseFloat(toDecimals(safeConfig.depositFeePercent, DENOMINATOR_DECIMALS)),
    claimFeePercent: parseFloat(toDecimals(safeConfig.claimFeePercent, DENOMINATOR_DECIMALS)),
    poolSavingPercent: parseFloat(toDecimals(safeConfig.poolSavingPercent, DENOMINATOR_DECIMALS)),
    apy: safeConfig.apy
  };
}

export const getWithdrawRequests = async () => {
  const [withdrawCounterFront, withdrawCounter] = (await Promise.all([
    bedRockCore.methods.withdrawCounterFront().call(),
    bedRockCore.methods.withdrawCounter().call()
  ])).map(x => parseInt(x));
  let withdrawRequests = await Promise.all(
    [...Array(withdrawCounter - withdrawCounterFront + 1).keys()].map(async (id) => {
      const withdrawRequest = await bedRockCore.methods.withdrawRequests(id + withdrawCounterFront).call();
      return { ...withdrawRequest, id: id + withdrawCounterFront };
    })
  );
  return withdrawRequests;
}

export const getLockedAssets = async () => {
  const [lockedCounterFront, lockedCounter] = (await Promise.all([
    bedRockCore.methods.lockedCounterFront().call(),
    bedRockCore.methods.lockedCounter().call()
  ])).map(x => parseInt(x));
  let lockedAssets = await Promise.all(
    [...Array(lockedCounter - lockedCounterFront + 1).keys()].map(async (id) => {
      const lockedAsset = await bedRockCore.methods.lockedAssets(id + lockedCounterFront).call();
      return { ...lockedAsset, id: id + lockedCounterFront };
    })
  );
  return lockedAssets;
}

export const getPrice = async (tokenAddress) => {
  const safeConfig = await getSafeConfig(tokenAddress);
  const _price = await getPriceProvider(safeConfig.priceProvider).methods.getPrice().call();
  const price = parseFloat(toDecimals(_price, STANDARD_DECIMALS));
  return price;
}

export const getWithdrawFeePercent = async (tokenAddress, delay) => {
  const _withdrawFeePercent = await bedRockCore.methods.withdrawFeePercent(tokenAddress, delay).call();
  const withdrawFeePercent = parseFloat(toDecimals(_withdrawFeePercent, DENOMINATOR_DECIMALS));
  return withdrawFeePercent;
}

export const getPoolAssets = async () => {
  if (bedRockCore) {
    const tokenCounter = parseInt(await bedRockCore.methods.tokenCounter().call());

    let poolAssets = await Promise.all(
      [...Array(tokenCounter).keys()].map(async (id) => {
        const tokenAddress = await bedRockCore.methods.tokenArray(id + 1).call();
        const amount = await getBalance(tokenAddress, bedRockCore.options.address);
        const tokenName = await getERC20(tokenAddress).methods.symbol().call();
        return { tokenAddress, amount, tokenName };
      })
    );
    return poolAssets;
  }
  return [];
}