import React, { useState, useEffect } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import SvgBackIcon from '../../../assets/images/BackIcon.svg';
import SvgWarnCircle from '../../../assets/images/WarnCircle.svg';
import { useWeb3React } from '@web3-react/core';
import {
  bedRockCore,
  getBalance,
  getSafeBalance,
  TypeSafeInfo
} from '../../../web3';
import styles from './SafeHeader.module.css';

type SafeHeaderProps = {
  safeInfo: TypeSafeInfo;
};

export function SafeHeader({ safeInfo }: SafeHeaderProps) {
  const { active, account } = useWeb3React();
  const [tokenBalance, setTokenBalance] = useState(0);
  const [safeBalance, setSafeBalance] = useState(0);
  const [bedRockUserBalance, setBedRockUserBalance] = useState(0);
  const [payoutRatio, setPayoutRatio] = useState(0);

  useEffect(() => {
    (async () => {
      const [_safeBalance, _tokenBalance, _bedRockUserBalance] = active ? (await Promise.all([
        getSafeBalance(safeInfo.tokenAddress, account),
        getBalance(safeInfo.tokenAddress, account),
        getBalance(bedRockCore.options.address, account)
      ])) : [0, 0, 0];
      setSafeBalance(_safeBalance);
      setTokenBalance(_tokenBalance);
      setBedRockUserBalance(_bedRockUserBalance);
    })();
  }, [safeInfo, active, account]);

  return (
    <Box className={styles.root}>
      <Link href={`/#/`} underline='none'>
        <Box className={styles.backButton}>
          <img className={styles.backIcon} src={SvgBackIcon} alt="back icon"/>
          <Typography className={styles.backText} variant='body1'>
            Back
          </Typography>
        </Box>
      </Link>
      <Grid className={styles.container} container spacing={4}>
        <Grid className={styles.tokenBox} item xs={12} sm={5} md={4} lg={3}>
          <Box className={styles.nameBox}>
            <Typography className={styles.tokenSymbol} variant='h3'>
              {safeInfo.tokenName}
            </Typography>
            <Typography className={styles.tokenName} variant='subtitle1'>
              {safeInfo.tokenName}
            </Typography>
          </Box>
          <Box>
            <Box className={styles.percentLineParent}>
              <Box className={styles.percentLineChild} width={payoutRatio}>
              </Box>
            </Box>
            <Typography className={styles.percentDesciption}>
              {parseFloat(safeInfo.totalSupply.toFixed(2))} {safeInfo.tokenName} remaining
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                {safeInfo.tokenName}
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                Protected Token&nbsp;
                <img src={SvgWarnCircle} alt="warn circle" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                1 Protected : {parseFloat(payoutRatio.toFixed(2))}
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                Payout Ratio&nbsp;
                <img src={SvgWarnCircle} alt="warn circle" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                ∞
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                Expiration Date&nbsp;
                <img src={SvgWarnCircle} alt="warn circle" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                Your Safe Balance
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                {parseFloat(safeBalance.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                Safe Total Supply
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                {parseFloat(safeInfo.totalSupply.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                Current Price
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                {parseFloat(safeInfo.price.toFixed(5))}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                Claim Lock Percent
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                {parseFloat((safeInfo.claimLockPercent * 100).toFixed(2))} %
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                Your Token Balance
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                {parseFloat(tokenBalance.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={styles.text1} variant='subtitle1'>
                Your BedRock Balance
              </Typography>
              <Typography className={styles.text2} variant='body1'>
                {parseFloat(bedRockUserBalance.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
