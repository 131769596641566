import React, { useContext, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SafeHeader } from './SafeHeader/SafeHeader';
import { SafeBody } from './SafeBody/SafeBody';
import { Portfolio } from './Portfolio/Portfolio';
import IconPortfolio from '../../components/svg/IconPortfolio';
import IconProtect from '../../components/svg/IconProtect';
import { TypeSafeInfo } from '../../web3';
import styles from './Safe.module.css';
import { useWeb3React } from '@web3-react/core';
import SafeContext from '../../context/SafeContext';

type TypePage = 'Safe' | 'Portfolio';

export function Safe() {
  const { account } = useWeb3React();
  const { safes } = useContext(SafeContext);
  const [page, setPage] = useState<TypePage>('Safe');
  const tokenName = useParams().id;
  const [safeInfo, setSafeInfo] = useState<TypeSafeInfo>();

  useEffect(() => {
    const _safeInfo = safes.find(safe => safe.tokenName === tokenName);
    setSafeInfo(_safeInfo);
  }, [tokenName, safes, account]);

  return (
    <Box className={styles.root}>
      <Box className={styles.topButtonGroup}>
        <Button
          className={styles.topButton}
          variant={page === 'Portfolio' ? 'contained' : 'outlined'}
          startIcon={<IconPortfolio fill={page === 'Portfolio' ? '#FFFFFF' : '#4DB2A8'} />}
          onClick={() => setPage('Portfolio')}
        >
          Portfolio
        </Button>
        <Button
          className={styles.topButton}
          variant={page === 'Safe' ? 'contained' : 'outlined'}
          startIcon={<IconProtect fill={page === 'Safe' ? '#FFFFFF' : '#4DB2A8'} />}
          onClick={() => setPage('Safe')}
        >
          Deposit & Protect
        </Button>
      </Box>
      {page === 'Portfolio' && <Portfolio />}
      {page === 'Safe' && safeInfo && (
        <>
          <SafeHeader safeInfo={safeInfo} />
          <SafeBody safeInfo={safeInfo} />
        </>
      )}
    </Box>
  );
}
