import React from 'react';
import { TypeSafeInfo } from '../web3';

interface ContextType {
  safes: TypeSafeInfo[];
  updateSafes: () => void;
}

const SafeContext = React.createContext<ContextType>({
  safes: [],
  updateSafes: () => { },
});

export default SafeContext;
