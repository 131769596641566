import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button
} from '@mui/material';
import { bedRockCore, getPoolAssets } from '../../../web3';
import styles from './Portfolio.module.css';

type TypePoolAsset = {
  tokenAddress: string;
  tokenName: string;
  amount: number;
};

export function Swapping() {
  const [poolAssets, setPoolAssets] = useState<TypePoolAsset[]>([]);

  useEffect(() => {
    if (bedRockCore) {
      (async () => {
        const _poolAssets = await getPoolAssets();
        setPoolAssets(_poolAssets);
      })();
    }
  }, [bedRockCore]);

  return (
    <Box className={styles.swapping}>
      <Typography className={styles.swappingTitle} variant='h3'>
        Pool Assets
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className={styles.th}>Token</Typography>
            </TableCell>
            <TableCell>
              <Typography className={styles.th}>Asset</Typography>
            </TableCell>
            <TableCell>
              <Typography className={styles.th}>Amount to swap</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {poolAssets.map((poolAsset, id) => (
            <TableRow className={styles.tr} key={id}>
              <TableCell>
                <Typography className={styles.td}>{poolAsset.tokenName}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.td}>{parseFloat(poolAsset.amount.toFixed(2))}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.td}>0</Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={styles.tr}>
            <TableCell>
            </TableCell>
            <TableCell>
              <Button className={styles.swapButton} variant='contained'>
                Swap
              </Button>
            </TableCell>
            <TableCell>
              <TextField type='number'/>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
