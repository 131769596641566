import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
import { AmountInput, AppCallButton } from '../../../components';
import { TypeSafeInfo, deposit } from '../../../web3';
import styles from './SafeBody.module.css';
import SafeContext from '../../../context/SafeContext';

type BoxProtectProps = {
  safeInfo: TypeSafeInfo;
};

export function BoxProtect({ safeInfo }: BoxProtectProps) {
  const { updateSafes } = useContext(SafeContext);
  const [fee, setFee] = useState(0);
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    if (safeInfo) {
      const _fee = amount * safeInfo.safeConfig.depositFeePercent;
      setFee(_fee);
    }
  }, [safeInfo, amount]);

  const onChangeAmount = (_amount: string) => {
    setAmount(parseFloat(_amount));
  }

  const callDeposit = async () => {
    toast.promise(
      deposit(
        safeInfo.tokenAddress,
        amount
      ).then(() => updateSafes()), {
      loading: 'Depositing...',
      success: 'Deposited!',
      error: 'Error!',
    });
  }

  return (
    <Box className={`${styles.boxApp} ${styles.boxProtect}`}>
      <AmountInput defaultValue={1} token={safeInfo.tokenName} onChange={onChangeAmount} />
      <Box className={styles.paramArea}>
        <Box className={styles.feeBox}>
          <Typography className={styles.paramName} variant='subtitle1'>
            Deposit fee
          </Typography>
          <Typography className={styles.paramValue} variant='subtitle1'>
            {parseFloat(fee.toFixed(2))}
          </Typography>
        </Box>
      </Box>
      <AppCallButton text='Protect' appFunc={callDeposit} />
    </Box>
  );
}
