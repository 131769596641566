import React from 'react';
import { Box } from '@mui/material';
import styles from './Safes.module.css';
import { SectionAddVault } from './SectionAddVault/SectionAddVault';
import { SectionOverview } from './SectionOverview/SectionOverview';
import { SectionSafeList } from './SectionSafeList/SectionSafeList';

export function Safes() {  
  return (
    <Box className={styles.root}>
      <SectionOverview />
      <SectionSafeList />
      <SectionAddVault />
    </Box>
  );
}
