import { Typography } from "@mui/material";
import { TypeSafeInfo } from "../../../web3";
import styles from './TokenProperty.module.css';

export function USDC({ safeInfo }: { safeInfo: TypeSafeInfo }) {
  return (
    <Typography className={styles.descriptionText} variant='body1'>
      This safe covers USD Coin (USDC), a digital stablecoin pegged to the U.S. dollar. It operates on the Ethereum, Stellar, Algorand, and Solana blockchains.
      Each USDC token is backed by $1 held in reserve and regularly a major accounting corporation.
      <br />
      <br />
      This safe covers the risk to reclaim USDC on Ethereum for less than {safeInfo.safeConfig.hackThreshold} USD.
      <br />
      <br />
      <strong>Risks</strong>
      <br />
      In the event of a depeg event, the policyholder will receive payment in a collection of stablecoins closer
      to their peg. Other Bedrock vault assets will be liquidated to partially compensate policy holders.
      The vault may be unable to provide the full value of the protection if another protocol
      in the vault experiences a default event before this pool does. While this event is unlikely
      to occur it is a possibility.
    </Typography>
  );
}
