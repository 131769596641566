import React, { useEffect, useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import styles from './Body.module.css';
import { Admin, Safes, Safe } from '../';
import {
  getSafes,
  TypeSafeInfo
} from '../../web3';
import { useWeb3React } from '@web3-react/core';
import SafeContext from '../../context/SafeContext';

export function Body() {
  const [safes, setSafes] = useState<TypeSafeInfo[]>([]);
  const { account } = useWeb3React();

  const updateSafes = async () => {
    const _safes = await getSafes();
    setSafes(_safes);
  }

  useEffect(() => {
    updateSafes();
  }, [account]);

  return (
    <Box className={styles.root}>
      <SafeContext.Provider value={{ safes, updateSafes }}>
        <HashRouter>
          <Routes>
            <Route path='/*' element={<Safes />} />
            <Route path='/safe/:id' element={<Safe />} />
            <Route path='/admin' element={<Admin />} />
          </Routes>
        </HashRouter>
      </SafeContext.Provider>
    </Box>
  );
}
