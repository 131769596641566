import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
import { AmountInput, AppCallButton } from '../../../components';
import { TypeSafeInfo, requestClaim } from '../../../web3';
import styles from './SafeBody.module.css';
import SafeContext from '../../../context/SafeContext';

type BoxClaimProps = {
  safeInfo: TypeSafeInfo;
};

export function BoxClaim({ safeInfo }: BoxClaimProps) {
  const { updateSafes } = useContext(SafeContext);
  const [fee, setFee] = useState(0);
  const [lockAmount, setLockAmount] = useState(0);
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    if (safeInfo) {
      const _fee = amount * safeInfo.safeConfig.claimFeePercent;
      setFee(_fee);
      const _lockAmount = amount * safeInfo.claimLockPercent;
      setLockAmount(_lockAmount);
    }
  }, [safeInfo, amount]);

  const onChangeAmount = (_amount: string) => {
    setAmount(parseFloat(_amount));
  }

  const callClaim = async () => {
    toast.promise(
      requestClaim(
        safeInfo.tokenAddress,
        amount
      ).then(() => updateSafes()), {
      loading: 'Claiming...',
      success: 'Claimed!',
      error: 'Error!',
    });
  }

  return (
    <Box className={`${styles.boxApp} ${styles.boxClaim}`}>
      <AmountInput defaultValue={1} token={safeInfo.tokenName} onChange={onChangeAmount} />
      <Box className={styles.paramArea}>
        <Box className={styles.feeBox}>
          <Typography className={styles.paramName} variant='subtitle1'>
            Claim fee
          </Typography>
          <Typography className={styles.paramValue} variant='subtitle1'>
            {parseFloat(fee.toFixed(2))}
          </Typography>
        </Box>
        <Box className={styles.lockBox}>
          <Typography className={styles.paramName} variant='subtitle1'>
            Lock Amount
          </Typography>
          <Typography className={styles.paramValue} variant='subtitle1'>
            {parseFloat(lockAmount.toFixed(2))}
          </Typography>
          <Typography className={styles.tailTag}>
            Lock Percentage: {parseFloat((safeInfo.claimLockPercent * 100).toFixed(3))} %
          </Typography>
        </Box>

      </Box>
      <AppCallButton text='Claim' appFunc={callClaim} />
    </Box>
  );
}
