import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
import { AmountInput, AppCallButton } from '../../../components';
import { TypeSafeInfo, withdrawRequest, getWithdrawFeePercent } from '../../../web3';
import styles from './SafeBody.module.css';
import SafeContext from '../../../context/SafeContext';

const DELAY_VALUES = [
  { value: 0.02083333, display: "30 min" },
  { value: 1, display: "1 day" },
  { value: 2, display: "2 days" },
  { value: 3, display: "3 days" }
].reverse();

type BoxWithdrawProps = {
  safeInfo: TypeSafeInfo;
};

export function BoxWithdraw({ safeInfo }: BoxWithdrawProps) {
  const { updateSafes } = useContext(SafeContext);
  const [fee, setFee] = useState(0);
  const [feePercent, setFeePercent] = useState(0);
  const [amount, setAmount] = useState(1);
  const [selectedDelayId, setSelectedDelayId] = useState(0);
  const [delay, setDelay] = useState(0);

  useEffect(() => {
    if (safeInfo) {
      const _fee = amount * feePercent;
      setFee(_fee);
    }
  }, [safeInfo, feePercent, amount]);

  useEffect(() => {
    const _delay = Math.round(DELAY_VALUES[selectedDelayId].value * 24 * 60);
    setDelay(_delay);
    (async () => {
      setFeePercent(await getWithdrawFeePercent(safeInfo.tokenAddress, _delay));
    })();
  }, [selectedDelayId, safeInfo.tokenAddress]);

  const onChangeAmount = (_amount: string) => {
    setAmount(parseFloat(_amount));
  }

  const callWithdraw = async () => {
    toast.promise(
      withdrawRequest(
        safeInfo.tokenAddress,
        amount,
        delay
      ).then(() => updateSafes()), {
      loading: 'Withdrawing...',
      success: 'Withdrawn!',
      error: 'Error!',
    });
  }

  return (
    <Box className={`${styles.boxApp} ${styles.boxWithdraw}`}>
      <AmountInput defaultValue={1} token={safeInfo.tokenName} onChange={onChangeAmount} />
      <Box className={styles.paramArea}>
        <Box className={styles.feeBox}>
          <Typography className={styles.paramName} variant='subtitle1'>
            Withdraw fee
          </Typography>
          <Typography className={styles.paramValue} variant='subtitle1'>
            {parseFloat(fee.toFixed(2))}
          </Typography>
        </Box>
        <Box className={styles.delayBox}>
          <Typography className={styles.paramName} variant='subtitle1'>
            Withdraw delay
          </Typography>
          <Box className={styles.delayList}>
            {DELAY_VALUES.map((delayValue, id) => (
              <Typography
                className={styles.delayValue + (selectedDelayId === id ? (' ' + styles.delayValueSelected) : '')}
                key={delayValue.value.toString()}
                variant='body1'
                onClick={() => setSelectedDelayId(id)}
              >
                {delayValue.display}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      <AppCallButton text='Withdraw' appFunc={callWithdraw} />
    </Box>
  );
}
