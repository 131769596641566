import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import styles from './WalletConnectButton.module.css';
import { injectedConnector, chainIds, NETWORK } from '../../web3';

export function WalletConnectButton() {
  const { activate, deactivate, active, account, library, chainId } = useWeb3React();

  const handleConnect = async () => {
    await activate(injectedConnector);
  };

  const handleDisconnect = () => {
    localStorage.removeItem('account');
    deactivate();
  }

  useEffect(() => {
    (async () => {
      const _account = localStorage.getItem('account');
      if (_account && _account.length > 0 && !active) {
        await handleConnect();
      }
    })();
  });

  useEffect(() => {
    if (account) localStorage.setItem('account', account);
    else localStorage.removeItem('account');
  }, [account]);

  useEffect(() => {
    console.log("Hello");
    if (active) {
      const network = (NETWORK || "Mainnet").toLowerCase() as ("mainnet" | "localhost" | "goerli");
      const id = chainIds[network];
      if (id !== chainId && library) {
        library.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x" + id?.toString(16) }],
        });
      }
    }
  }, [active, chainId, library?.provider]);

  return (
    <Button
      className={styles.root}
      variant="contained"
      color="primary"
      onClick={() => { !active ? handleConnect() : handleDisconnect() }}
    >
      {!active ? 'Connect your wallet' : account?.substring(0, 10) + '...'}
    </Button>
  );
}
