import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { SafeSummary, SafeSummaryDataProps } from './SafeSummary';
import styles from './SectionSafeList.module.css';
import { toDecimals, DENOMINATOR_DECIMALS, getBalance, getDecimals } from '../../../web3';
import { useWeb3React } from '@web3-react/core';
import { TOKEN_PROPERTIES } from '../TokenProperty';
import SafeContext from '../../../context/SafeContext';

export function SectionSafeList() {
  const { active, account } = useWeb3React();
  const { safes } = useContext(SafeContext);
  const [safeSummaryDatas, setSafeSummaryDatas] = useState<SafeSummaryDataProps[]>([]);

  useEffect(() => {
    (async () => {
      const _safes = (await Promise.all(safes.map(async safe => {
        const safeData = TOKEN_PROPERTIES.find(safeData => safeData.token === safe.tokenName);
        if (!safeData) return undefined;
        const token = safeData.token;
        const icon = safeData.icon;
        const iconBgColor = safeData.iconBgColor;
        const capacity = safe.totalSupply;
        const depositFee = parseFloat(toDecimals(safe.safeConfig.depositFeePercent, DENOMINATOR_DECIMALS));
        const apy = parseFloat(toDecimals(safe.safeConfig.apy, DENOMINATOR_DECIMALS));
        const userPosition = active ? (await getBalance(safe.tokenAddress, account, await getDecimals(safe.tokenAddress))) : 0;
        return {
          token,
          icon,
          iconBgColor,
          capacity,
          depositFee,
          apy,
          userPosition
        };
      }))).filter(x => x !== undefined) as SafeSummaryDataProps[];

      setSafeSummaryDatas(_safes);
    })();
  }, [safes, account, active]);

  return (
    <Box className={styles.root}>
      <Grid container spacing={3}>
        {safeSummaryDatas.map((safeSummaryData, id) => (
          <Grid item xs={12} md={6} key={id}>
            <SafeSummary {...safeSummaryData} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
