import React from 'react';
import { Box } from '@mui/material';
import { SafeList } from './SafeList';
import { Swapping } from './Swapping';
import styles from './Portfolio.module.css';

export function Portfolio() {
  return (
    <Box className={styles.root}>
      <SafeList />
      <Swapping />
    </Box>
  );
}
