import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { BoxProtect } from './BoxProtect';
import { BoxWithdraw } from './BoxWithdraw';
import { BoxClaim } from './BoxClaim';
import { PendingAssets } from './PendingAssets';
import { TypeSafeInfo } from '../../../web3';
import { TOKEN_PROPERTIES } from '../../Safes/TokenProperty';
import styles from './SafeBody.module.css';

type TypeActivePage = 'Protect' | 'Withdraw' | 'Claim';

type SafeBodyProps = {
  safeInfo: TypeSafeInfo;
};

export function SafeBody({ safeInfo }: SafeBodyProps) {
  const [activePage, setActivepage] = useState<TypeActivePage>('Protect');

  const tokenProperty = TOKEN_PROPERTIES.find(tokenProperty => tokenProperty.token === safeInfo.tokenName);
  const Description = tokenProperty ? tokenProperty.desciption : null;

  return (
    <Box className={styles.root}>
      <Grid className={styles.container} container spacing={2}>
        <Grid item xs={12} md={7}>
          <Box className={styles.descriptionBox}>
            <Typography className={styles.tokenName} variant='h2'>
              {safeInfo.tokenName}
            </Typography>
            <Box>
              {Description && <Description safeInfo={safeInfo} />}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className={styles.appBox}>
            <Box className={styles.buttonGroup}>
              <Button
                className={`${styles.buttonAppSelect} ${activePage === 'Protect' ? styles.buttonActive : styles.buttonInactive}`}
                variant={activePage === 'Protect' ? 'contained' : 'text'}
                onClick={() => setActivepage('Protect')}
              >
                Protect
              </Button>
              <Button
                className={`${styles.buttonAppSelect} ${activePage === 'Withdraw' ? styles.buttonActive : styles.buttonInactive}`}
                variant={activePage === 'Withdraw' ? 'contained' : 'text'}
                onClick={() => setActivepage('Withdraw')}
              >
                Withdraw
              </Button>
              <Button
                className={`${styles.buttonAppSelect} ${activePage === 'Claim' ? styles.buttonActive : styles.buttonInactive}`}
                variant={activePage === 'Claim' ? 'contained' : 'text'}
                onClick={() => setActivepage('Claim')}
              >
                File Claim
              </Button>
            </Box>
            {activePage === 'Protect' && <BoxProtect safeInfo={safeInfo} />}
            {activePage === 'Withdraw' && <BoxWithdraw safeInfo={safeInfo} />}
            {activePage === 'Claim' && <BoxClaim safeInfo={safeInfo} />}
          </Box>
        </Grid>
      </Grid>
      <PendingAssets safeInfo={safeInfo} />
    </Box>
  );
}
