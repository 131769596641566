import React from 'react';
import './App.css';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Header, Body, Footer } from './pages';
import { Toaster } from 'react-hot-toast';

const getLibrary = (provider: any, connector: any): Web3Provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

function App() {
  return (
    <div className='App'>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Header />
        <Body />
        <Footer />
      </Web3ReactProvider>
      <Toaster />
    </div>
  );
}

export default App;