import {
  getSafeConfig,
  getPriceProvider,
  DENOMINATOR_DECIMALS,
  toNDecimals,
  STANDARD_DECIMALS,
  ZERO_ADDRESS
} from "./common";

export const createSafe = async (bedRockCore, stableCoin, expectPrice, fromAddress = undefined) => {
  await bedRockCore.methods.createSafe(stableCoin, expectPrice, ZERO_ADDRESS).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}

export const activateSafe = async (bedRockCore, safeAddress, flag, fromAddress = undefined) => {
  await bedRockCore.methods.activateSafe(safeAddress, flag).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}

export const setPrice = async (tokenAddress, _price, fromAddress = undefined) => {
  const safeConfig = await getSafeConfig(tokenAddress);
  const price = toNDecimals(_price, STANDARD_DECIMALS);
  await getPriceProvider(safeConfig.priceProvider).methods.setPrice(price).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}

export const setConfigValue = async (bedRockCore, tokenAddress, param, newValue, fromAddress = undefined) => {
  const oldConfig = await bedRockCore.methods.getSafeConfig(tokenAddress).call();
  if (param.endsWith("Percent") || param === "hackThreshold") {
    newValue = toNDecimals(newValue, DENOMINATOR_DECIMALS);
  }
  const newConfig = { ...oldConfig, [param]: newValue };
  await bedRockCore.methods.updateSafeConfig(tokenAddress, newConfig).send({
    from: fromAddress ? fromAddress : window.ethereum.selectedAddress
  });
}
