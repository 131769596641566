import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './SectionOverview.module.css';
import SvgSubtract1 from '../../../assets/images/Subtract1.svg';
import SvgSubtract2 from '../../../assets/images/Subtract2.svg';
import { getSafeCnt } from '../../../web3';

export function SectionOverview() {
  const [safeCnt, setSafeCnt] = useState(0);

  useEffect(() => {
    (async () => {
      setSafeCnt(await getSafeCnt());
    })();
  }, []);

  return (
    <Box className={styles.root}>
      <Box className={styles.topStates}>
        <Box className={styles.topState}>
          <Box className={`${styles.leftColorPanel} ${styles.leftColorPanel1}`}>
            <img className={styles.subtractIcon1} src={SvgSubtract1} alt="subtract 1" />
          </Box>
          <Box className={styles.rightTextPanel}>
            <Typography className={styles.topStateValue} variant='h5'>
              {safeCnt}
            </Typography>
            <Typography className={styles.topStateTitle} variant='body1'>
              Active Coverage
            </Typography>
          </Box>
        </Box>
        <Box className={styles.topState}>
          <Box className={`${styles.leftColorPanel} ${styles.leftColorPanel2}`}>
            <img className={styles.subtractIcon2} src={SvgSubtract2} alt="subtract 2" />
          </Box>
          <Box className={styles.rightTextPanel}>
            <Typography className={styles.topStateValue} variant='h5'>
              _
            </Typography>
            <Typography className={styles.topStateTitle} variant='body1'>
              Ongoing Claims
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography className={styles.title} variant='h3'>
        BEDROCK VAULTS
      </Typography>
    </Box>
  );
}
