import { Typography } from "@mui/material";
import { TypeSafeInfo } from "../../../web3";
import styles from './TokenProperty.module.css';

export function USDT({ safeInfo }: { safeInfo: TypeSafeInfo }) {
  return (
    <Typography className={styles.descriptionText} variant='body1'>
      This Safe provides insurance on Tether (USDT) is the world’s largest stablecoin by total market capitalization and is pegged at a 1:1
      ratio to the U.S. dollar. Tether’s USDT stablecoin is owned and operated by Tether Limited, which is the parent company of the centralized
      cryptocurrency exchange Bitfinex
      <br />
      <br />
      This safe covers the risk to reclaim USDT on Ethereum for less than {safeInfo.safeConfig.hackThreshold} USD
      <br />
      <br />
      <strong>Risks</strong>
      <br />
      In the event of a depeg event, the policyholder will receive payment in a collection of stablecoins closer
      to their peg. Other Bedrock vault assets will be liquidated to partially compensate policy holders.
      The vault may be unable to provide the full value of the protection if another protocol
      in the vault experiences a default event before this pool does. While this event is unlikely
      to occur it is a possibility.
    </Typography>
  );
}
