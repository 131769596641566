import React from 'react';
import { Button } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import styles from './AppCallButton.module.css';
import { injectedConnector } from '../../web3';
import SvgWallet from '../../assets/images/Wallet.svg';

type AppCallButtonProps = {
  text: string;
  appFunc: () => void;
}

export function AppCallButton({ text, appFunc }: AppCallButtonProps) {
  const { activate, active } = useWeb3React();

  const handleConnect = async () => {
    await activate(injectedConnector);
  };

  return (
    <Button
      className={styles.root}
      startIcon={!active && (<img src={SvgWallet} alt="wallet" />)}
      variant='contained'
      onClick={() => active ? appFunc() : handleConnect()}
    >
      {active ? text : 'Connect Wallet'}
    </Button>
  );
}
