
import SvgDAI from '../../../assets/images/DAI.svg';
import SvgUSDC from '../../../assets/images/USDC.svg';
import SvgUSDT from '../../../assets/images/USDT.svg';
import SvgBUSD from '../../../assets/images/BUSD.svg';
import { DAI } from './DAI';
import { USDC } from './USDC';
import { USDT } from './USDT';
import { BUSD } from './BUSD';

export const TOKEN_PROPERTIES = [
  {
    token: 'USDC',
    icon: SvgUSDC,
    iconBgColor: '#FAFAFA',
    desciption: USDC
  },
  {
    token: 'DAI',
    icon: SvgDAI,
    iconBgColor: '#F5AC37',
    desciption: DAI
  },
  {
    token: 'USDT',
    icon: SvgUSDT,
    iconBgColor: '#00A477',
    desciption: USDT
  },
  {
    token: 'BUSD',
    icon: SvgBUSD,
    iconBgColor: '#F0B90B',
    desciption: BUSD
  }
];