import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button
} from '@mui/material';
import { toast } from 'react-hot-toast';
import styles from './Admin.module.css';
import {
  activateSafe,
  bedRockCore,
  createSafe,
  getERC20,
  TOKEN_LIST,
  setConfigValue,
  setPrice,
  toNDecimals,
  ZERO_ADDRESS
} from '../../web3';
import { useWeb3React } from '@web3-react/core';
import SafeContext from '../../context/SafeContext';

const CONFIG_PARAMS = [
  'FeeFreePeriod',
  'HackThreshold',
  'ClaimLockPeriod',
  'DepositFeePercent',
  'ClaimFeePercent',
  'PoolSavingPercent'
].map(displayName => {
  return {
    fieldName: displayName[0].toLowerCase() + displayName.slice(1),
    displayName
  }
});

type TypeSafe = {
  tokenName: any;
  tokenAddress: any;
  property: any | undefined;
}

export function Admin() {
  const { account } = useWeb3React();
  const { safes, updateSafes } = useContext(SafeContext);
  const [adminAddress, setAdminAddress] = useState("_");
  const [safeCandidates, setSafeCandidates] = useState<TypeSafe[]>([]);

  const [newPrice, setNewPrice] = useState(0);
  const [newValues, setNewValues] = useState<any>({});

  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedSafe, setSelectedSafe] = useState<TypeSafe | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const admin = await bedRockCore.methods.owner().call();
      setAdminAddress(admin);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const safeCandidates_ = Object.entries(TOKEN_LIST).map(entry => {
        const [tokenName, tokenAddress] = entry;
        const property = safes.find(safe => safe.tokenAddress.toLowerCase() === tokenAddress.toLowerCase());
        return {
          tokenName,
          tokenAddress,
          property
        };
      });
      setSafeCandidates(safeCandidates_);
    })();
  }, [safes]);

  useEffect(() => {
    (async () => {
      if (selectedRow === -1 || !safeCandidates[selectedRow].tokenAddress) {
        setSelectedSafe(undefined);
      }
      else {
        setSelectedSafe(safeCandidates[selectedRow]);
      }
    })();
  }, [selectedRow, safeCandidates]);

  const setNewValue = (paramName: string, newValue: string) => {
    setNewValues({ ...newValues, [paramName]: newValue });
  }

  const updateConfigValue = (paramName: string) => {
    toast.promise(
      setConfigValue(
        bedRockCore,
        selectedSafe?.tokenAddress || ZERO_ADDRESS,
        paramName,
        newValues[paramName]
      ).then(() => updateSafes()), {
      loading: "Updating Config Value",
      success: "Config Updated",
      error: "Failed to Update Config"
    });
  }

  const callCreateSafe = async (safeCandidate: TypeSafe) => {
    const token = getERC20(safeCandidate.tokenAddress);
    const decimals = await token.methods.decimals().call();
    toast.promise(
      createSafe(
        bedRockCore,
        safeCandidate.tokenAddress,
        toNDecimals(1, decimals)
      ).then(() => updateSafes()), {
      loading: "Creating Safe",
      success: "Safe Created",
      error: "Failed to Create Safe"
    });
  }

  const callActivateSafe = async (safe: TypeSafe, flag: boolean) => {
    toast.promise(
      activateSafe(
        bedRockCore,
        safe.tokenAddress,
        flag
      ).then(() => updateSafes()), {
      loading: flag ? "Activating Safe" : "Deactivating Safe",
      success: flag ? "Safe Activated" : "Safe Deactivated",
      error: flag ? "Failed to Activate Safe" : "Failed to Deactivate Safe"
    });
  }

  return (
    <Box className={styles.root}>
      {(!account || adminAddress.toLowerCase() !== account.toLowerCase()) ? (
        <Typography className={styles.sectionTitle} variant='h3'>
          Current Address is not the admin account
        </Typography>
      ) : (
        <Box>
          <Box className={styles.configBox}>
            <Typography className={styles.sectionTitle} variant='h2'>
              Config
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={styles.th}>Param</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Value</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>New Value</Typography>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CONFIG_PARAMS.map(configParam => (
                  <TableRow className={styles.tr} key={configParam.displayName}>
                    <TableCell className={styles.td}>
                      <Typography>{configParam.displayName}</Typography>
                    </TableCell>
                    <TableCell className={styles.td}>
                      <Typography>{selectedSafe?.property?.safeConfig?.[configParam.fieldName]}</Typography>
                    </TableCell>
                    <TableCell className={styles.td}>
                      <TextField type='number' variant='standard' onChange={
                        (e) => setNewValue(configParam.fieldName, e.target.value)
                      } sx={{ bgcolor: 'grey' }} />
                    </TableCell>
                    <TableCell className={styles.td}>
                      <Button onClick={() => updateConfigValue(configParam.fieldName)}>
                        {'Set New ' + configParam.displayName}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={styles.tr}>
                  <TableCell className={styles.td}>
                    <Typography>Price</Typography>
                  </TableCell>
                  <TableCell className={styles.td}>
                    <Typography>{selectedSafe?.property?.price}</Typography>
                  </TableCell>
                  <TableCell className={styles.td}>
                    <TextField type='number' variant='standard' onChange={(e) => setNewPrice(parseFloat(e.target.value))} sx={{ bgcolor: 'grey' }} />
                  </TableCell>
                  <TableCell className={styles.td}>
                    <Button onClick={() => setPrice(selectedSafe?.tokenAddress, newPrice)}>
                      {'Set New Price'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box className={styles.safesBox}>
            <Typography className={styles.sectionTitle} variant='h2'>
              Safes
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={styles.th}>Safe</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Token</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Token Address</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Price Provider</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>In Active</Typography>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Price</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {safeCandidates.map((safeCandidate, id) => (
                  <TableRow className={styles.tr + (id === selectedRow ? (' ' + styles.selectedTr) : '')} key={id} onClick={() => setSelectedRow(id === selectedRow ? -1 : id)}>
                    <TableCell className={styles.td}>
                      {safeCandidate.property ? safeCandidate.tokenName : (
                        <Button onClick={() => callCreateSafe(safeCandidate)}>
                          Create Safe
                        </Button>
                      )}
                    </TableCell>
                    <TableCell className={styles.td}>
                      <Typography>
                        {safeCandidate.tokenName}
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.td}>
                      <Typography>
                        {safeCandidate.tokenAddress}
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.td}>
                      <Typography>
                        {safeCandidate.property ? safeCandidate.property.safeConfig.priceProvider : (
                          <TextField type='string' variant='standard' sx={{ bgcolor: 'grey' }} />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.td}>
                      <Typography>
                        {safeCandidate?.tokenAddress && (safeCandidate?.property?.isActive ? "Activated" : "Deactivated")}
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.td}>
                      {safeCandidate?.tokenAddress && (
                        <Button onClick={() => callActivateSafe(safeCandidate, !safeCandidate?.property?.isActive)}>{
                          safeCandidate?.property?.isActive ? "Deactive" : "Active"
                        }</Button>
                      )}
                    </TableCell>
                    <TableCell className={styles.td}>
                      <Typography>
                        {safeCandidate?.property?.price}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}
    </Box>
  );
}
