import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import styles from './AmountInput.module.css';

type AmountInputProps = {
  maxAmount?: number;
  defaultValue: number;
  token: string;
  onChange: (value: string) => void;
}

export function AmountInput({ maxAmount, defaultValue, token, onChange }: AmountInputProps) {
  return (
    <Box className={styles.root}>
      <Typography className={styles.tagAmount} variant='body1'>
        Amount
      </Typography>
      <Typography className={styles.tagMax}>
        Max
      </Typography>
      <TextField
        className={styles.input}
        variant='standard'
        type='number'
        defaultValue={defaultValue}
        InputLabelProps={{ shrink: true }}
        inputProps={{ sx: { textAlign: 'right' } }}
        InputProps={{ disableUnderline: true }}
        onChange={(e) => onChange(e.target.value)}
      />
      <Button className={styles.tokenButton} variant='contained'>
        {token}
      </Button>
    </Box>
  );
}
