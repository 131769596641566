import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid
} from '@mui/material';
import styles from './SafeBody.module.css';
import { getLockedAssets, getWithdrawRequests, toDecimals, TypeSafeInfo } from '../../../web3';
import { useWeb3React } from '@web3-react/core';

type PendingAssetsProps = {
  safeInfo: TypeSafeInfo;
};

type TypePendingAsset = {
  id: number;
  user: string;
  releaseTime: number;
  amount: number;
};

export function PendingAssets({ safeInfo }: PendingAssetsProps) {
  const { account } = useWeb3React();
  const [withdrawRequests, setWithdrawRequests] = useState<TypePendingAsset[]>([]);
  const [lockedAssets, setLockedAssets] = useState<TypePendingAsset[]>([]);

  useEffect(() => {
    (async () => {
      const [_withdrawRequests, _lockedAssets] = account ? (await Promise.all([
        (await getWithdrawRequests()).filter(asset =>
          asset.user.toLowerCase() === account.toLowerCase() && asset.token.toLowerCase() === safeInfo.tokenAddress.toLowerCase()
        ),
        (await getLockedAssets()).filter(asset =>
          asset.user.toLowerCase() === account.toLowerCase() && asset.token.toLowerCase() === safeInfo.tokenAddress.toLowerCase()
        )
      ])) : [[], []];
      setWithdrawRequests(_withdrawRequests);
      setLockedAssets(_lockedAssets);
    })();
  }, [safeInfo, account]);

  return (
    <Box className={styles.pendingAssets}>
      <Grid className={styles.pendingContainer} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={styles.pendingBox}>
            <Typography className={styles.pendingTitle} variant='h3'>
              Pending Withdraw Requests
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={styles.th}>Id</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Proceed Time</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Amount</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawRequests.map(pendingAsset => (
                  <TableRow className={styles.tr} key={pendingAsset.id}>
                    <TableCell>
                      <Typography className={styles.td}>{pendingAsset.id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={styles.td}>{new Date(pendingAsset.releaseTime * 1000).toLocaleString("en-US")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={styles.td}>{parseFloat(parseFloat(toDecimals(pendingAsset.amount, safeInfo.decimals)).toFixed(4))}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.pendingBox}>
            <Typography className={styles.pendingTitle} variant='h3'>
              Locked Assets
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={styles.th}>Id</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Unlock Time</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.th}>Amount</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lockedAssets.map(pendingAsset => (
                  <TableRow className={styles.tr} key={pendingAsset.id}>
                    <TableCell>
                      <Typography className={styles.td}>{pendingAsset.id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={styles.td}>{new Date(pendingAsset.releaseTime * 1000).toLocaleString("en-US")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={styles.td}>{parseFloat(parseFloat(toDecimals(pendingAsset.amount, safeInfo.decimals)).toFixed(4))}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
