import { InjectedConnector } from '@web3-react/injected-connector';

export * from './admin';
export * from './common';
export * from './user';
export * from './types';

export const injectedConnector = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 1337, 31337, 80001] });

export const chainIds = {
    mainnet: 1,
    goerli: 5,
    localhost: 31337,
};
